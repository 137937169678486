// main.js

// jQuery scripts in here:
jQuery(function ($) {
	
	
	
	//$('.tiles-widget').tilesWidget();
	
	var hash = window.location.hash;
	if(hash){
		//console.log(hash);
		tile_id = hash.replace("#", "");
		//console.log(tile_id);
		setTimeout(function(){ clickTile(tile_id); }, 500);   
	}

	

	$(document).ready(function(){
		$('.bxslider').bxSlider({
			mode: 'fade',
			pagerCustom: '#bx-pager'
		});



    	$('.pod').matchHeight({
    		byRow: false,
    		property: 'height',
    		target: null,
    		remove: false
		});
	});
    
    $(document).ready(function(){
			$(".large-plan").hide();
			$(".show_hide").show();
			$('.show_hide').click(function(e){
				e.preventDefault();
				$(".large-plan").slideToggle();
			});
	});


console.log('xx');
	MicroModal.init({
		//onShow: console.info('show'), // [1]
		onClose: function () {
			var src = $('#vid-walkthrough').attr('src');
			$('#vid-walkthrough').attr('src', null );
			$('#vid-walkthrough').attr('src', src );
			//$('#vid-walkthrough').attr('src', $('#vid-walkthrough').attr('src') )
		}, // [2]
		//openTrigger: 'data-custom-open', // [3]
		//closeTrigger: 'data-custom-close', // [4]
		openClass: 'is-open', // [5]
		disableScroll: true, // [6]
		disableFocus: false, // [7]
		awaitOpenAnimation: false, // [8]
		awaitCloseAnimation: false, // [9]
		debugMode: true // [10]
	});

	

});
